import { useEffect, useState } from "react";
import LiveChat from "react-livechat";
import Footer from "../../components/Footer/Footer";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";

function CSKH() {
	const [profile, setProfile] = useState(null);
	const [uri, setUri] = useState('{"uid":"guest"}');
	const [isShow, setShow] = useState(false);
	axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem("user");

			if (token) {
				config.headers["Authorization"] = `Bearer ${token}`;
			}

			return config;
		},

		(error) => {
			return Promise.reject(error);
		}
	);

	const [isLoading, setIsLoading] = useState(false);
	useEffect(() => {
		setIsLoading(true);
		const timer = setTimeout(() => {
			setIsLoading(false);
			window.scrollTo(0, document.body.scrollHeight);
		}, 1000);
		return () => clearTimeout(timer);
	}, []);
	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
			.then((res) => {
				const data = res.data.data;
				const idx = data?.iduser || 'guest';
				// setProfile(data);
				setUri(JSON.stringify({"uid": idx}));
			})
			.catch((err) => localStorage.removeItem("user"));
	}, []);
	return (
		<>
			{isLoading ? (
				<div className="loading">
					<div className="loader"></div>
				</div>
			) : null}
			<div className="main">
				{/* <Header profile={profile} />
				<h1 className="title-h1">Chăm Sóc Khách Hàng</h1> */}
				<div style={{height: '0.2rem'}}></div>
				<div style={{ position: "relative", height: "calc(100vh - 2.2rem" }}>
					<iframe
						// src={`https://vm.providesupport.com/1q37rv6b64gnr0dbs7bb743lk1?ID=${profile?.iduser || 'guest'}`}
						src={`https://kzhonl.ocbnyutpfwjs.in/index?key=e4818bf3574828343e68c95c6e3d134f&lang=vi&pf=inside&userInfo=${encodeURIComponent(uri)}`}
						frameBorder="0"
						width="100%"
						height="100%"
					></iframe>
				</div>
			</div>
			<Footer />
		</>
	);
}

export default CSKH;
